import * as React from "react";
import ShortUniqueId from "short-unique-id";
import "./Automation.scss";
import { connect } from "react-redux";
import { apiPrefix, baseTitle } from "./../../helper";
import axios from "axios";
import ReactLoading from "react-loading";
import { Link } from "react-router-dom";
import Layout from "./../Layout";
import { Helmet } from "react-helmet";
import TextareaAutosize from "react-textarea-autosize";
import { ToastContainer, toast } from "react-toastify";
import { logoutUser } from "./../login/actions/authActions";
import qs from "qs";
import { changeItems } from "./actions/automationAction";
import ReactTooltip from "react-tooltip";

import AddElments from "./AddElments";

import Audio from "./audio/Audio";
import QuickReply from "./quick-reply/QuickReply";
import Text from "./text/Text";
import Picture from "./picture/Picture";
import Video from "./video/Video";
import File from "./file/File";
import Gallery from "./gallery/Gallery";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface Props {
  token: string;
  location: any;
  flows: [];
  logoutUser: () => any;
  items: any;
  changeItems: (items: []) => any;
  history: any;
  match: any;
}

const TITLE = `${baseTitle} Automation`;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid
  // width: 700
});

interface selectedFlow {
  uid: string;
  name: string;
  content: any;
}

class Automation extends React.Component<
  Props,
  {
    users: [] | null;
    flows: [];
    selectedFlow: selectedFlow | null;
    quickReplies: any;
    hide_modal_input: boolean;
    witValue: string;
    allWits: [];
    saved: boolean;
    newFlow: boolean;
    initialFlow: boolean;
    sendBlockClicked: Boolean;
    replyFlowname: string;
    replyTitle: string;
    filteredFlows: [],
    flowsFetched: boolean,
    allFlows: []
  }
> {
  inputTitle: HTMLInputElement;

  constructor(props: Props) {
    super(props);
    this.state = {
      users: null,
      flows: [],
      selectedFlow: null,
      sendBlockClicked: false,
      replyFlowname: "",
      replyTitle: "",
      quickReplies: [],
      hide_modal_input: false,
      witValue: "",
      allWits: [],
      saved: true,
      newFlow: false,
      initialFlow: false,
      filteredFlows: [],
      allFlows: [],
      flowsFetched: false
    };
    this.getFlows();
    this.props.changeItems([]);
  }

  getFlows = async () => {
    try {
      let url = apiPrefix + "automation";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        console.log("all flows res",response.data.data.flows)
        this.setState({ flows: response.data.data.flows, filteredFlows: response.data.data.flows, allFlows: response.data.data.flows, flowsFetched: true  });
        if (!this.state.initialFlow && !this.props.match.params.flow && response.data.data.flows.length > 0) {
          this.retrieveFlow(response.data.data.flows[0].uid);
          this.setState({ initialFlow: true});
        } else {
          this.retrieveFlow();
        }
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  componentDidMount() {
    this.retrieveFlow();
    this.getFlows();
  }

  addOrUpdateFlow = async (newContent = null, title = null, quickReplies = null) => {
    try {
      this.setState({ saved: false });
      let suid: any = new ShortUniqueId();
      let uid = suid.randomUUID(12).toUpperCase();
      let url = `${apiPrefix}automation`;
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let content = [];
      let data;
      if (newContent) {
        console.log("Add or update flow: ", newContent);
          console.log(newContent)
          let result = []
          if(newContent.length > 0){
            result = newContent.filter((item, i) => delete item.quick_replies);
            result[result.length - 1].quick_replies = quickReplies ? quickReplies : (this.state.quickReplies ? this.state.quickReplies : [])
            console.log("Filtered quick replies: ", result[result.length -1])
          }
          data = {
            uid: this.state.selectedFlow.uid,
            name: this.state.selectedFlow.name,
            content: JSON.stringify(result)
          }
      } else {
        data = {
          uid: uid,
          name: title ? title : "Untitled",
          content: JSON.stringify(content)
        };
      }
      console.log("Adding this data", data);
      let response = await axios.post(url, qs.stringify(data), {
        headers: headers
      });

      console.log("Add flow response yeeeee: ", response);
      if (response.data.success) {
        this.setState({ saved: true });
        this.getFlows();
        if (newContent) {
          this.props.changeItems(newContent);
          this.retrieveFlow();
        } else {
          this.inputTitle.focus();
          this.props.history.push(`/automation/${uid}`);
        }
      }
    } catch (error) {
      console.log("Flow add error", error);
      // this.props.logoutUser()
    }
  };

  retrieveFlow = async (uid = null) => {
    this.setState({ saved: true,});
    console.log("retrieving flow");
    if (uid || this.props.match.params.flow) {
      try {
        let url = `${apiPrefix}automation/${
          uid ? uid : this.props.match.params.flow
        }`;
        console.log(`sent url : ${url}`);
        let headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`
        };
        let response: any = await axios({
          method: "get",
          url: url,
          headers: headers
        });
        console.log("response flow: ", response);
        if (response.data.success && response.data.data.flow.content) {
          let flow = response.data.data.flow;
          let selectedFlow = {
            name: flow.name,
            uid: flow.uid,
            content: flow.content
          };
          response.data.data.flow.content.map((item, i) => item.hasOwnProperty('quick_replies') ? this.setState({quickReplies: item.quick_replies}) : null)
          console.log("Retrieve response", response.data.data.flow);
          console.log("Retrieve response selected flow",selectedFlow);
          this.setState({ selectedFlow });
          this.props.changeItems(response.data.data.flow.content);
          this.props.history.push(`/automation/${flow.uid}`);
          let url = `${apiPrefix}wit/samples/intent/${flow.name
            .replace(/\s+/g, "_")
            .toUpperCase()}`;
          let headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`
          };
          let responseWit = await axios.get(url, {
            headers: headers
          });
          if (responseWit.data.data.samples) {
            this.setState({ allWits: responseWit.data.data.samples });
            console.log("all wits ", responseWit.data.data.samples);
          }
        }
      } catch (error) {
        console.log(error);
        // this.props.logoutUser()
      }
    }
  };

  addItem = (type: string) => {
    if (this.state.selectedFlow) {
      let items: any = [...this.props.items];

      let uid: any = new ShortUniqueId();
      let id = uid.randomUUID(12).toUpperCase();

      switch (type) {
        case "image":
          items.push({ id: id, image: null });
          break;
        case "gallery":
          items.push({
            id: id,
            generic: [
              {
                id: uid.randomUUID(12),
                title: null,
                image_url: null,
                subtitle: null,
                buttons: []
              }
            ]
          });
          break;
        case "text":
          items.push({ id: id, text: "", buttons: [] });
          break;
        case "audio":
          items.push({ id: id, audio: null });
          break;
        case "video":
          items.push({ id: id, video: null });
          break;
        case "file":
          items.push({ id: id, file: null });
          break;
      }

      let flow = this.state.selectedFlow;
      flow.content = items;
      this.setState({ selectedFlow: flow });
      this.addOrUpdateFlow(items);
      // this.setState({ items })

      console.log(`All items : ${JSON.stringify(items)}`);
    } else {
      toast.error("Please select or add a new flow.");
    }
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items: any = reorder(
      this.props.items,
      result.source.index,
      result.destination.index
    );
    this.props.changeItems(items);
    this.addOrUpdateFlow(items);
  };

  changeFlowName = e => {
    if (
      this.state.selectedFlow &&
      e.target.value != " " &&
      e.target.value != null
    ) {
      let selectedFlow = this.state.selectedFlow;
      selectedFlow.name = e.target.value;
      this.setState({ selectedFlow });
    }
  };

  deleteFlow = async () => {
    try {
      let url = `${apiPrefix}automation/${this.state.selectedFlow.uid}`;
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response = await axios({
        method: "delete",
        url: url,
        headers: headers
      });
      console.log("Flow delete response: ", response);
      if (response.data.success) {
        this.setState({ selectedFlow: null, allWits: [] });
        // this.inputTitle.value = "";
        this.getFlows();
        this.props.changeItems([]);
        let selectedFlow: any = this.state.flows.shift();
        this.setState({ selectedFlow });
        this.retrieveFlow(selectedFlow.uid);
      } else {
        toast.error("Something wrong..");
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  renameFlow = () => {
    if (this.state.selectedFlow) {
      this.addOrUpdateFlow(this.props.items);
    } else {
      toast.error("Please select or add a new flow.");
    }
  };

  addWit = async e => {
    try {
      if (
        this.props.match.params.flow &&
        this.props.match.params.flow &&
        this.state.selectedFlow &&
        this.state.witValue != " " &&
        this.state.witValue &&
        e.key === "Enter"
      ) {
        let url = `${apiPrefix}wit/sample`;
        let headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`
        };
        let data = {
          intent: this.state.selectedFlow.name
            .replace(/\s+/g, "_")
            .toUpperCase(),
          text: this.state.witValue
        };
        let response = await axios.post(url, qs.stringify(data), {
          headers: headers
        });
        if (response.data.data.status.sent) {
          let allWits: any = [...this.state.allWits];
          allWits.push(this.state.witValue);

          this.setState({ witValue: "", allWits });
        }
        console.log(response.data.data);
      }
    } catch (error) {
      console.log("Errror from wit", error);
    }
  };

  filterFlows = e => {
    let updateList: any = this.state.flows.filter((item: any) => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      filteredFlows: updateList,
      replyFlowname: e.target.value
    });
  };

  addQuickReply = () =>{
    if(this.state.replyTitle && this.state.replyFlowname){
      let quickReplies = [...this.state.quickReplies]
      quickReplies.push({
        content_type: "text",
        title: this.state.replyTitle,
        payload: this.state.replyFlowname
      })
      this.setState({quickReplies, replyFlowname: " ", replyTitle: " "})
      this.addOrUpdateFlow(this.props.items, null, quickReplies)
    }
    
  }

  searchFlow = (e) =>{
    let updateList: any = this.state.allFlows.filter((item: any) => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      flows: updateList,
      replyFlowname: e.target.value
    });
  }

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div className="main-content">
            <div className="container_padding">
              <div className="main_chatbot_file">
                <div>
                  <div className="row">
                    <div className="col-md-4 full-height">
                      <div className="main_lft_bot pt_75">
                        <div className="lft_header">
                          <h4>Flows of your bot</h4>
                        </div>
                        <div>
                          <input className="form-control col-md-12 mt-3 searchFlow"
                            onChange={(e)=> this.searchFlow(e)} 
                            placeholder="Search Flow"/>
                        </div>
                        <div className="inner_lft_section">
                          {/* <div className="inner_lft_section_btn">
                            <button type="button">Welcome Message</button>
                            <button type="button">Default Message</button>
                          </div> */}
                          <div className="flow_title d-flex justify-content-space-between pr-0">
                            <h5 style={{ width: "60%" }}>Flows</h5>
                            <div
                              className="innner_lft_main_button"
                              onClick={() => {this.addOrUpdateFlow(); this.setState({quickReplies: []})}}
                            >
                                <button
                                  type="button"
                                  className="custome_button add-button"
                                >
                                  +Add Flow
                                </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              {!this.state.flowsFetched && (
                                <ReactLoading
                                  className="ml-auto mr-auto"
                                  type={"cylon"}
                                  color={"#e20001"}
                                  height={"30%"}
                                  width={"30%"}
                                />
                              )}

                              <div className="lft_inpt">
                                <ul>
                                  {[...this.state.flows].map(
                                    (flow: any, index: number) => (
                                      <li key={index}>
                                        <div
                                          className="innner_lft_main_button"
                                          onClick={() => {
                                            this.retrieveFlow(flow.uid);
                                            this.setState({
                                              selectedFlow: flow,
                                              quickReplies: []
                                            });
                                            console.log("clicked flow", flow);
                                          }}
                                        >
                                          <Link to={`/automation/${flow.uid}`}>
                                            <button
                                              type="button"
                                              className={`${
                                                this.state.selectedFlow
                                                  ? this.state.selectedFlow
                                                      .uid == flow.uid
                                                    ? "slected-flow"
                                                    : "custome_button"
                                                  : "custome_button"
                                              }`}
                                            >
                                              {flow.name.slice(0, 10)}
                                              {flow.name.length > 10
                                                ? "..."
                                                : null}
                                            </button>
                                          </Link>
                                        </div>
                                      </li>
                                    )
                                  )}
                                  {/* {<li>
                                                                        <div className="innner_lft_main_button" onClick={() => this.addOrUpdateFlow()}>
                                                                            <Link to={`/automation`}><button type="button" className="custome_button">+Add Flow</button></Link>
                                                                        </div>
                                                                    </li>} */}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 padding_0 full-height padding_lft_20">
                      <div
                        className={`right_header pt_75 text-center ${
                          this.state.selectedFlow ? "d-flex" : "d-none"
                        }`}
                      >
                        <form className="row m-0">
                          <div className="form-group control_block">
                            <input
                              ref={el => (this.inputTitle = el)}
                              onFocus={event => event.target.select()}
                              id="block_name"
                              type="text"
                              onBlur={() => this.renameFlow()}
                              onChange={e => this.changeFlowName(e)}
                              className="form-control block_name_placeholder"
                              placeholder="Flow Name"
                              value={
                                this.state.selectedFlow
                                  ? this.state.selectedFlow.name
                                  : null
                              }
                              data-tip="Change flow name."
                              data-for="flow-name-input"
                            />
                            {this.inputTitle &&
                            this.inputTitle.value == "Untitled"
                              ? ReactTooltip.show(this.inputTitle)
                              : ReactTooltip.hide(this.inputTitle)}
                          </div>
                          {this.state.selectedFlow && this.state.saved && (
                            <div className={"ml-2"}>
                              <p>
                                <i
                                  className="fa fa-check saved-icon pr-1"
                                  aria-hidden="true"
                                ></i>
                                <span className={"saved-icon"}>Saved</span>
                              </p>
                            </div>
                          )}
                          {this.state.selectedFlow && !this.state.saved && (
                            <div className={"ml-2 mt-1 row"}>
                              <ReactLoading
                                className="ml-auto mr-auto pr-2 pt-1"
                                type={"spokes"}
                                color={"#ccc"}
                                height={30}
                                width={30}
                              />
                              <span className={"mt-1"}>Saving</span>
                            </div>
                          )}
                        </form>
                        <div className="save_button">
                          <button
                            data-toggle="modal"
                            data-target="#deletFlowModal"
                            type="button"
                            className="save_button"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                      <div className="main_collapse">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              data-toggle="tab"
                              href="#home"
                            >
                              Bot Response
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#menu1"
                            >
                              If User Says
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content">
                        <div className="tab-pane active" id="home">
                          <div className="">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="droppable" type="app">
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                      snapshot.isDraggingOver
                                    )}
                                  >
                                    {[...this.props.items].map(
                                      (item: any, index) => (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div>
                                              <div
                                                className="drag"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(
                                                  snapshot.isDragging,
                                                  provided.draggableProps.style
                                                )}
                                              >
                                                <span
                                                  className="drag_icon"
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    display: "inline-block",
                                                    margin: "0 0px"
                                                  }}
                                                >
                                                  ↕
                                                </span>
                                                {item.hasOwnProperty(
                                                  "text"
                                                ) && (
                                                  <Text
                                                    flows={this.state.flows}
                                                    item={item}
                                                    index={index}
                                                    addOrUpdateFlow={
                                                      this.addOrUpdateFlow
                                                    }
                                                  />
                                                )}
                                                {item.hasOwnProperty(
                                                  "generic"
                                                ) && (
                                                  <Gallery
                                                    item={item}
                                                    index={index}
                                                    addOrUpdateFlow={
                                                      this.addOrUpdateFlow
                                                    }
                                                    flows={this.state.flows}
                                                  />
                                                )}
                                                {item.hasOwnProperty(
                                                  "image"
                                                ) && (
                                                  <Picture
                                                    item={item}
                                                    index={index}
                                                    addOrUpdateFlow={
                                                      this.addOrUpdateFlow
                                                    }
                                                  />
                                                )}
                                                {item.hasOwnProperty(
                                                  "audio"
                                                ) && (
                                                  <Audio
                                                    item={item}
                                                    addOrUpdateFlow={
                                                      this.addOrUpdateFlow
                                                    }
                                                  />
                                                )}
                                                {item.hasOwnProperty(
                                                  "video"
                                                ) && (
                                                  <Video
                                                    item={item}
                                                    index={index}
                                                    addOrUpdateFlow={
                                                      this.addOrUpdateFlow
                                                    }
                                                  />
                                                )}
                                                {item.hasOwnProperty(
                                                  "file"
                                                ) && (
                                                  <File
                                                    item={item}
                                                    addOrUpdateFlow={
                                                      this.addOrUpdateFlow
                                                    }
                                                  />
                                                )}
                                              </div>
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>

                            <div
                              className="right_message_box text-center"
                              id="show_box"
                            ></div>
                            <div
                              id="show_gallery_box"
                              className="opacity"
                            ></div>
                            {[...this.props.items].length > 0 && <div className="quickReply">
                              {[...this.state.quickReplies].map(
                                (item, index) => {
                                  {
                                    return (
                                      <QuickReply
                                        key={index}
                                        flows={this.state.flows}
                                        item={item}
                                        index={index}
                                        addOrUpdateFlow={this.addOrUpdateFlow}
                                        quickReplies={[...this.state.quickReplies]}
                                      />
                                    );
                                  }
                                }
                              )}
                              <button
                                className="quick_subtitle ml-2"
                                type="button"
                                data-toggle="modal"
                                data-target={`#quick_reply`}
                              >
                                + Quick reply
                              </button>
                            </div>}

                            {this.state.selectedFlow && (
                              <AddElments addItem={this.addItem} />
                            )}

                            <div
                              className="modal fade"
                              id={`quick_reply`}
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-body">
                                    <div className="button_title">
                                      <p>Quick Reply:</p>
                                      <input
                                        type="text"
                                        placeholder="Button Title"
                                        className="button_title_input"
                                        id="quick_input_buttton"
                                        onChange={(e)=> this.setState({replyTitle: e.target.value})}
                                        value={this.state.replyTitle}
                                      />
                                    </div>
                                    <div className="button_title_body">
                                      <p>When This Button is Pressed</p>
                                      <div
                                        className={`${
                                          this.state.hide_modal_input
                                            ? "hide_modal_input "
                                            : ""
                                        } button_box border_all`}
                                        onClick={() =>
                                          this.setState({
                                            hide_modal_input: true,
                                            sendBlockClicked: true
                                          })
                                        }
                                      >
                                        <i
                                          className="fa fa-commenting-o padding_top_icon"
                                          aria-hidden="true"
                                        ></i>
                                        <h5>Flow Name</h5>
                                      </div>
                                      <div
                                        className={`${
                                          this.state.hide_modal_input
                                            ? ""
                                            : "button_hide"
                                        }`}
                                      >
                                        {this.state.sendBlockClicked && (
                                          <div className="dropdown_bx dropdown-menu">
                                            <ul className="">
                                              {this.state.filteredFlows.map(
                                                (flow: any, index) => (
                                                  <li
                                                    key={index}
                                                    onClick={() =>
                                                      this.setState({
                                                        replyFlowname: flow.name
                                                      })
                                                    }
                                                  >
                                                    {flow.name}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        )}
                                        <input
                                          data-toggle="dropdown"
                                          // ref={this.sendblockInput}
                                          value={this.state.replyFlowname}
                                          onChange={e => this.filterFlows(e)}
                                          className={
                                            this.state.sendBlockClicked
                                              ? "hide_input"
                                              : "hide_input HIDE"
                                          }
                                          type="text"
                                          placeholder="Flow Name"
                                          autoFocus
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      data-dismiss="modal"
                                      id="Save_Done"
                                      onClick={()=> this.addQuickReply()}
                                    >
                                      Done
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane main_right_another_bot"
                          id="menu1"
                        >
                          {this.state.selectedFlow && (
                            <TextareaAutosize
                              onKeyDown={this.addWit}
                              value={this.state.witValue}
                              onChange={e =>
                                this.setState({ witValue: e.target.value })
                              }
                              className="user_textarea"
                              placeholder="Press Enter to Submit Phrases"
                            />
                          )}
                          {[...this.state.allWits].length > 0 && (
                            <div className="text_block">
                              {[...this.state.allWits].map((wit, index) => (
                                <p className="block_name" key={index}>
                                  {wit}
                                  {/* <div className="block_delete">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </div> */}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal"
            id="deletFlowModal"
            role="dialog"
            aria-labelledby="deletFlowModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="deletFlowModal">
                    Delete{" "}
                    {this.state.selectedFlow
                      ? this.state.selectedFlow.name
                      : null}
                  </h5>
                </div>
                <div className="modal-body">
                  This action cannot be undone. Are you sure?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="slected-flow"
                    data-dismiss="modal"
                    onClick={() => {this.deleteFlow(); this.props.history.push('/automation')}}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <ReactTooltip effect="solid" id="flow-name-input" place={"bottom"} />
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken,
  items: state.automation.items
});

export default connect(mapStateToProps, { logoutUser, changeItems })(
  Automation
);
