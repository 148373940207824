import React, { Component } from "react";
import { Line } from 'react-chartjs-2';


interface Props {
  chart: any
}



export default class LineChart extends Component<Props, {}> {

  datasets = () => {
    try {
      return this.props.chart.datasets.map((item: any, index: number) => {
        return (
          {
            label: item.label,
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: item.color,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: item.color,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: item.color,
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: item.data
          }
        )
      })
    } catch (error) {
      console.log(error)
    }
  }

  data = () => {
    return {
      labels: this.props.chart.labels,

      datasets: this.datasets()

    };
  }

  render() {
    const options = {
      legend: {
        display: true,
        position: 'bottom'
      },
    };
    return (
      <div>
        <Line
          height={90} data={this.data()} options={options} />
      </div>
    );
  }
};