import React, { Component } from 'react'

interface Props {
    addItem: (type: string) => any
}

class AddElments extends React.Component <Props>{
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="automation-title">
                    <h4>Add Component</h4>
                </div>

                <div className="icon_box_section_1 icon_box_center">

                    <div className="text box_style margin_box" id="text" onClick={() => this.props.addItem('text')}>
                        <i className="fa fa-align-left" aria-hidden="true"></i>
                        <p>+ Text</p>
                    </div>

                    <div className="text box_style margin_box" id="picture" onClick={() => this.props.addItem('image')}>
                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                        <p>+ Picture</p>
                    </div>
                    <div className="text box_style margin_box" id="gallery" onClick={() => this.props.addItem('gallery')}>
                        <i className="fa fa-credit-card" aria-hidden="true"></i>
                        <p>+ Gallery</p>
                    </div>
                    <div className="text box_style margin_box" id="audio" onClick={() => this.props.addItem('audio')}>
                        <i className="fa fa-volume-off" aria-hidden="true"></i>
                        <p>+ Audio</p>
                    </div>
                    <div className="text box_style margin_box" id="video" onClick={() => this.props.addItem('video')}>
                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                        <p>+ Video</p>
                    </div>
                    <div className="text box_style margin_box" id="file" onClick={() => this.props.addItem('file')}>
                        <i className="fa fa-align-left" aria-hidden="true"></i>
                        <p>+ File</p>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
export default AddElments