import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import qs from "qs";
import { apiPrefix, baseTitle } from "./../../helper";
import { Helmet } from "react-helmet";
import LineChart from "./../linechart/LineChart";
import ReactWeather from "react-open-weather";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "./../login/actions/authActions";

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
  inputStyle: any;
  onChangeSelectedFlow: any;
}

class FlowInput extends Component<
  Props,
  {
    chart: [];
    allFlows: [];
    flows: [];
    selectedFlowName: string;
    value: string
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chart: [],
      allFlows: [],
      flows: [],
      selectedFlowName: "",
      value: ""
    };
    this.getFlows();
  }

  getFlows = async () => {
    try {
      let url = apiPrefix + "automation";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        this.setState({
          allFlows: response.data.data.flows,
          flows: response.data.data.flows
        });
      }
    } catch (error) {
      console.log(error);
      // this.props.logoutUser();
    }
  };

  

  filterFlows = e => {
    let updateList: any = this.state.allFlows;
    updateList = updateList.filter(item => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });

    this.setState({
      flows: updateList,
      value: e.target.value
    });
  };

  addWit = async () => {
    try {
      if (this.state.selectedFlowName) {
        let url = `${apiPrefix}wit/sample`;
        let headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`
        };
        let data = {
          intent: this.state.selectedFlowName
            .replace(/\s+/g, "_")
            .toUpperCase(),
          text: this.state.selectedFlowName
        };
        let response = await axios.post(url, qs.stringify(data), {
          headers: headers
        });
        if (response.data.data.status.sent) {
          this.setState({ selectedFlowName: "", value: ""});
          toast.success("Trained Successfully")
        }
        console.log(response.data.data);
      }
    } catch (error) {
      console.log("Errror from wit", error);
    }
  };

  render() {
    return (
      <div className="_select_box align-items-center">
        <div className="dropdown_bx dropdown-menu" style={{width: '25%'}}>
          <ul className="">
            {this.state.flows.map((flow: any, index) => (
              <li
                key={index}
                onClick={() => {
                  this.setState({
                    selectedFlowName: flow.name,
                  });
                }}
              >
                {flow.name}
              </li>
            ))}
          </ul>
        </div>
        <input
          style={JSON.parse(this.props.inputStyle)}
          data-toggle="dropdown"
          // ref={this.sendblockInput}
          value={this.state.selectedFlowName ? this.state.selectedFlowName : this.state.value}
          onChange={e => this.filterFlows(e)}
          className={"hide_input"}
          type="text"
          placeholder="Select Flow"
          autoFocus
        />
        <p className="btn btn-sm btn-secondary ml-2 mt-2" onClick={() => this.addWit()}>
          Train
        </p>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(FlowInput);
