import * as React from 'react'
import './header.scss';
import {
    Link
} from 'react-router-dom';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { AiOutlineMail } from 'react-icons/ai';
import { FiMessageSquare } from 'react-icons/fi';
import { FiLogOut } from 'react-icons/fi';
import UcblSVG from '../../assets/UcblSVG.svg';
interface Props {

}

export const Header: React.FC<Props> = () => {
    return (
        <React.Fragment>

            <nav className="navbar navbar-expand-lg navbar-light header">
                {/* <div className="logo" id="sideLogo">
                    <Link to="/"><h3 className="header_text">Chatlogy</h3>
                </div> */}
                <div className="logo">
                    <Link to="/"><img src="https://dl.dropbox.com/s/rkctl6awbxicpy3/Frame.svg" /> </Link>
                </div>
                <div className="genex_logo">
                    <p>UCBL</p>
                    
                    <div className={"logo_container"}>
                        <img src={"https://www.moumaachi.com/upload/company/united-commercial-bank-limited-atm,-mawna.jpg"} />
                    </div>
                    
                </div>
                {/* <div className="row d-flex justify-content-end w-100">
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <IoMdNotificationsOutline className="headerIcons" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in fadeOut">
                            <div className="dropdown-content-heading">
                                <span className="text-left">Recent Notifications</span>
                            </div>
                            <ul>
                                <li className="row notification-item">
                                    <div className="col-sm-2">
                                        <img src="assets/avatar.jpg" />
                                    </div>
                                    <div className="col-sm-7">
                                        <p className="notification-header">Mr. Wolf</p>
                                        <p className="notification">5 member joined today</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p>02:34 PM</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <AiOutlineMail className="headerIcons" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in">
                            <a className="dropdown-item" href="#">Action 2</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Separated link</a>
                        </div>
                    </div>
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <FiMessageSquare className="headerIcons" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in">
                            <a className="dropdown-item" href="#">Action 3</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Separated link</a>
                        </div>
                    </div>
                    <div className="btn-group">
                        <div data-toggle="dropdown" >
                            <img className="profile" src="assets/avatar.jpg" />
                        </div>
                        <div className="dropdown-menu dropdown-menu-header fade-in">
                            <a className="dropdown-item" href="#">Log out</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">Separated link</a>
                        </div>
                    </div>


                </div> */}
            </nav>
        </React.Fragment>
    );
}
