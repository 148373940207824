import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import "./dashboard.scss";
import jwtDecode from "jwt-decode";
import { Sparklines, SparklinesBars } from "react-sparklines";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiPrefix, baseTitle } from "./../../helper";
import LineChart from "./../linechart/LineChart";
import ReactWeather from "react-open-weather";
import "react-open-weather/lib/css/ReactWeather.css";
import { connect } from "react-redux";
import Layout from "./../Layout";
import { logoutUser } from "./../login/actions/authActions";

const TITLE = `${baseTitle} Dashboard`;

const options = {
  legend: {
    display: true,
    position: "bottom"
  }
};
const options1 = {
  legend: {
    display: true,
    position: "bottom"
  }
};
const data = {
  labels: ["Male", "Female"],
  datasets: [
    {
      data: [90, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
    }
  ]
};
const data1 = {
  labels: ["Male", "Female"],
  datasets: [
    {
      data: [300, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
    }
  ]
};

interface Props {
  token: any;
  location: any;
  logoutUser: () => any;
}

interface Overview {
  readonly title: string;
  readonly count: number;
  readonly bar: string;
}

class Dashboard extends Component<
  Props,
  {
    overviews: [] | null;
    top_flows: [];
    messageActivity: any;
    userActivity: any;
    msgNotRecognizedByAi: [];
    filterMsgActivity: string;
    filterUserActivity: string;
    recentUsers: []
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      overviews: [],
      top_flows: [],
      messageActivity: null,
      userActivity: null,
      msgNotRecognizedByAi: [],
      filterMsgActivity: "weekly",
      filterUserActivity: "weekly",
      recentUsers: []
    };
    this.fetchOverView();
    this.getTopFlows();
    this.getMesssageActivity();
    this.getUserActivity();
    this.getMsgNotRecognizedByAi();
    this.getRecentUsers()
    // console.log("Jwt decoded token: ", jwtDecode(this.props.token))
  }

  getRecentUsers = async () => {
    try {
      let url = `${apiPrefix}recentUsers`;
      console.log(url);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        let recentUsers = response.data.data.users
        this.setState({ recentUsers});
        console.log("recent users",recentUsers)
      }
    } catch (error) {
      console.log("Recent users", error);
    }
  };


  getMesssageActivity = async (filter = "weekly") => {
    try {
      let url = `${apiPrefix}messageActivity/${filter}`;
      console.log(url);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      if (response.data.success) {
        let messageActivity = response.data.data.messageActivity;
        this.setState({ messageActivity, filterMsgActivity: filter });
        console.log("Message activity", response.data.data.messageActivity);
      }
    } catch (error) {
      console.log("User activity", error);
    }
  };

  getUserActivity = async (filter = "weekly") => {
    try {
      let url = `${apiPrefix}userActivity/${filter}`;
      console.log(url);
      console.log("filter useractivity", filter);
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });

      if (response.data.success) {
        let userActivity = response.data.data.userActivity;
        this.setState({ userActivity, filterUserActivity: filter });
        console.log("User activity", response.data.data.userActivity);
      }
    } catch (error) {
      console.log("User activity error: ", error);
    }
  };

  getMsgNotRecognizedByAi = async () => {
    try {
      let url = apiPrefix + "not-recognized";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });

      if (response.data.success) {
        let msgNotRecognizedByAi = response.data.data.messages;
        this.setState({ msgNotRecognizedByAi });
        console.log("Msg Not Recognized By Ai: ", response.data.data.messages);
      }
    } catch (error) {
      console.log("User activity error: ", error);
    }
  };

  getTopFlows = async () => {
    try {
      let url = apiPrefix + "top-flows";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Top flows response", response);
      if (response.data.success) {
        this.setState({ top_flows: response.data.data.top_flows });
        console.log("Top flows", response.data.data.top_flows);
      }
    } catch (error) {}
  };

  fetchOverView = async () => {
    try {
      let url = apiPrefix + "overview";
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${this.props.token}`
      };
      let response: any = await axios({
        method: "get",
        url: url,
        headers: headers
      });
      console.log("Overview response", response.data);
      if (response.data) {
        this.setState({ overviews: response.data.data.overview });
      }
    } catch (error) {
      console.log(error);
      this.props.logoutUser();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Layout {...this.props}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <div className="row m-0 full-height" style={{ paddingTop: 60 }}>
            {window.scrollTo(0, 0)}
            {this.state.overviews &&
              this.state.overviews.map((item: Overview, index: number) => (
                <div className="col-md-3" key={index}>
                  <div className="dashboard-card">
                    <div className="card-header">
                      <h4>{item.title}</h4>
                      <h4 className="count">{item.count}</h4>
                    </div>
                    <div className="spark-line">
                      <Sparklines
                        data={[
                          5,
                          10,
                          5,
                          20,
                          9,
                          15,
                          18,
                          5,
                          10,
                          5,
                          20,
                          9,
                          15,
                          18
                        ]}
                      >
                        <SparklinesBars />
                      </Sparklines>
                    </div>
                  </div>
                </div>
              ))}

            <div className="col-md-6 mt_20">
              <div className="msg_activity">
                <div className="activity_message card">
                  <div className="activity_header">
                    <div className="activity_message_title">
                      <h4>Message Activity</h4>
                      <p>Last seen days</p>
                    </div>
                    <div className="activity_button">
                      <button
                        type="button"
                        onClick={() => this.getMesssageActivity("weekly")}
                        className={`activity_btn ${
                          this.state.filterMsgActivity == "weekly"
                            ? "msg_active_btn"
                            : "btn-inactive"
                        }`}
                      >
                        Weekly
                      </button>
                      <button
                        type="button"
                        onClick={() => this.getMesssageActivity("monthly")}
                        className={`activity_btn ${
                          this.state.filterMsgActivity == "monthly"
                            ? "msg_active_btn"
                            : "btn-inactive"
                        }`}
                      >
                        Monthly
                      </button>
                    </div>
                  </div>
                  <div className="activity_body">
                    <div className="activity_body_header">
                      <span>Incoming</span>
                      <span>Outgoing</span>
                    </div>
                    <div className="activity_body_middle">
                      <div className="incoming padding_top">
                        <h5>Incoming</h5>
                        <h3 className="margin_top">
                          {this.state.messageActivity &&
                            this.state.messageActivity.currentRangeIncoming}
                        </h3>
                        <p className="percentage">
                          {this.state.messageActivity &&
                            this.state.messageActivity.incomingRatio}
                          <i
                            className={`fa fa-caret-up ${
                              this.state.messageActivity &&
                              this.state.messageActivity.incomingStatus ==
                                "positive"
                                ? "color_green"
                                : `color_red`
                            }`}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                      <div className="outgoing padding_top">
                        <h5>Outgoing</h5>
                        <h3 className="margin_top">
                          {this.state.messageActivity &&
                            this.state.messageActivity.currentRangeOutgoing}
                        </h3>
                        <p className="percentage">
                          {this.state.messageActivity &&
                            this.state.messageActivity.outgoingRatio}{" "}
                          <i
                            className={`fa fa-caret-up ${
                              this.state.messageActivity &&
                              this.state.messageActivity.outgoingStatus ==
                                "positive"
                                ? "color_green"
                                : `color_red`
                            }`}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                      <div className="total padding_top">
                        <h5>Total</h5>
                        <h3 className="margin_top">
                          {this.state.messageActivity &&
                            this.state.messageActivity.totalMessage}
                        </h3>
                        <p className="percentage">
                          {this.state.messageActivity &&
                            this.state.messageActivity.totalMessageRatio}{" "}
                          <i
                            className={`fa fa-caret-up ${
                              this.state.messageActivity &&
                              this.state.messageActivity.totalStatus ==
                                "positive"
                                ? "color_green"
                                : `color_red`
                            }`}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="msg_view">
                    <a href="#">
                      View Message Activity{" "}
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt_20">
              <div className="msg_activity">
                <div className="activity_message card">
                  <div className="activity_header">
                    <div className="activity_message_title">
                      <h4>User Activity</h4>
                      <p>Today's</p>
                    </div>

                    <div className="activity_button">
                      <button
                        type="button"
                        onClick={() => this.getUserActivity("weekly")}
                        className={`activity_btn ${
                          this.state.filterUserActivity == "weekly"
                            ? "msg_active_btn"
                            : "btn-inactive"
                        }`}
                      >
                        Weekly
                      </button>
                      <button
                        type="button"
                        onClick={() => this.getUserActivity("daily")}
                        className={`activity_btn ${
                          this.state.filterUserActivity == "daily"
                            ? "msg_active_btn"
                            : "btn-inactive"
                        }`}
                      >
                        Daily
                      </button>
                    </div>
                  </div>
                  <div className="activity_body">
                    <div className="activity_body_header">
                      <span>New</span>
                      <span>Lifetime</span>
                    </div>
                    <div className="activity_body_middle">
                      <div className="incoming padding_top">
                        <h5>New Users</h5>
                        <h3 className="margin_top">
                          {this.state.userActivity &&
                            this.state.userActivity.currentUsers}
                        </h3>
                        <p className="percentage">
                          {this.state.userActivity &&
                            this.state.userActivity.userRatio}
                          <i
                            className={`fa fa-caret-up ${
                              this.state.userActivity &&
                              this.state.userActivity.userStatus == "positive"
                                ? "color_green"
                                : `color_red`
                            }`}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                      <div className="outgoing padding_top">
                        <h5>Lifetime Users</h5>
                        <h3 className="margin_top">
                          {this.state.userActivity &&
                            this.state.userActivity.totalUsers}
                        </h3>
                        <p className="percentage font-weight-bold pl-1"> -</p>
                      </div>
                      <div className="total padding_top">
                        <h5>Message By Users</h5>
                        <h3 className="margin_top">
                          {this.state.userActivity &&
                            this.state.userActivity.currentMessages}
                        </h3>
                        <p className="percentage">
                          {this.state.userActivity &&
                            this.state.userActivity.messageRatio}{" "}
                          <i
                            className={`fa fa-caret-up ${
                              this.state.userActivity &&
                              this.state.userActivity.messageStatus ==
                                "positive"
                                ? "color_green"
                                : `color_red`
                            }`}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="msg_view">
                    <a href="#">
                      View Message Activity{" "}
                      <i className="fa fa-caret-up" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 mt_20">
                            <div className="user-table user_main_table card">
                                <div className="user_table_header">
                                    <h4>User Retention</h4>
                                    <p>Nov 12 -Nov 19 </p>
                                </div>
                                <table className="table table-responsive table-hover N_table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Users</th>
                                            <th>Day 1</th>
                                            <th>Day 2</th>
                                            <th>Day 3</th>
                                            <th>Day 4</th>
                                            <th>Day 5</th>
                                            <th>Day 6</th>
                                            <th>Day 7</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total</td>
                                            <td>4255</td>
                                            <td>4255</td>
                                            <td>4255</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>

                                        </tr>
                                        <tr>
                                            <td>Nov 12</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>23.65%</td>
                                            <td>23.65%</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>

                                        </tr>
                                        <tr>
                                            <td>Nov 13</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>

                                        </tr>
                                        <tr>
                                            <td>Nov 14</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>
                                        </tr>
                                        <tr>
                                            <td>Nov 15</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>
                                        </tr>
                                        <tr>
                                            <td>Nov 16</td>
                                            <td>4255</td>
                                            <td>23.65%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>02.47%</td>
                                            <td>11.98%</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="msg_view">
                                    <a href="#">View User Retention <i className="fa fa-caret-up"
                                        aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div> */}
            <div className="col-md-6 mt_20">
              <div className="user-table user_main_table card flow_height">
                <div className="user_table_header_flow">
                  <h4>Top Flows</h4>
                </div>
                <div className="flow_body">
                  <div className="flow_header D_flex">
                    <p>Flows</p>
                    <p>Count</p>
                  </div>
                  <div className="flow_scroll">
                    {this.state.top_flows.length > 0 &&
                      this.state.top_flows.map((flow: any, index: number) => {
                        return (
                          <div className="flow_list" key={index}>
                            <span>{flow.name}</span>
                            <span className="float-right">{flow.count}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="msg_view margin_top_7">
                  <a href="#">
                    View Top Flows{" "}
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt_20">
              <div className="user-table user_main_table card flow_height">
                <div className="user_table_header_flow">
                  <h4>Message Not Recognized By AI</h4>
                </div>
                <div className="flow_body">
                  <div className="flow_header D_flex">
                    <p>Flows</p>
                  </div>
                  <div className="flow_scroll">
                    {this.state.msgNotRecognizedByAi.length > 0 &&
                      this.state.msgNotRecognizedByAi.map(
                        (item: any, index: number) => {
                          return (
                            <div className="flow_list" key={index}>
                              <span>{item.message}</span>
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
                <div className="msg_view margin_top_7">
                  <Link to="/retrain">
                    Retrain
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt_20">
              <div className="card">
                <h4>Recent Users</h4>
                <table className="user_table table table-responsive table-hover ">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Name</th>
                      <th>joining_date</th>
                      <th>joined</th>
                      <th>Sessions</th>
                      {/* <th>Last Message</th> */}
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.recentUsers.map((user: any, i) => (
                      <tr key={i}>
                      <th>
                        <img
                          className="peoples_img"
                          src={user.profile_pic}
                        />
                      </th>
                      <th>{user.first_name} {user.last_name}</th>
                      <th>{user.joining_date}</th>
                      <th>{user.joined}</th>
                      <th>{user.session}</th>
                      {/* <th>show me</th> */}
                      
                    </tr>
                    ))}
                  </tbody>
                </table>
                <div className="msg_view margin_top_7">
                  <Link to="/leadgen">
                    View All Users{" "}
                    <i className="fa fa-caret-up" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            {/*{this.state.chart && (*/}
            {/*    <div className="p-3 col-md-12 mt-2 mr-2">*/}
            {/*        <div className="card p-2">*/}
            {/*            <div className="card-header">*/}
            {/*                <h4 className="ml-4">Header</h4>*/}
            {/*            </div>*/}
            {/*            <div className="card-body">*/}
            {/*                <LineChart chart={this.state.chart} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{!this.state.chart && <span>Loading...</span>}*/}
          </div>
          <div className="row">
            <div className="col-md-3">
              <Doughnut data={data} options={options} />
            </div>
            <div className="col-md-3">
              <Doughnut data={data1} options={options1} />
            </div>
          </div>

          {/* <ReactWeather
                        forecast="today"
                        apikey="02b236a2b574c000f3dc1fec62038044"
                        type="geo"
                        lat="48.1351"
                        lon="11.5820"
                    /> */}
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  token: state.auth.currentUserToken
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
