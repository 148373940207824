import React, { Component } from "react";
import "./sidebar.scss";
import { NavLink } from "react-router-dom";
import { TiHomeOutline } from "react-icons/ti";
import { FaRegUser } from "react-icons/fa";
import { FiLayout } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { MdPeopleOutline } from "react-icons/md";
import { FaRocketchat } from "react-icons/fa";
import { IoMdStats } from "react-icons/io";
import { TiFlowMerge } from "react-icons/ti";
import { connect } from "react-redux";
import { logoutUser } from "./../login/actions/authActions";

interface Props {
  logoutUser: () => any;
  location: any;
  match: any;
}

class Sidebar extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getNavLinkClass = path => {
    return this.props.location.pathname === path ? "active" : "";
  };

  render() {
    return (
      <React.Fragment>
        <div className="sidebar sidebar-hide-to-small sidebar-shrink sidebar-gestures">
          <div className="nano">
            <div className="nano-content">
              <ul className="pt-2">
                <li className={this.getNavLinkClass("/")}>
                  <NavLink to="/">
                    <TiHomeOutline className="sidebar-icon" /> 
                    <p>Dashboard</p>
                    {" "}
                  </NavLink>
                </li>

                <li
                  className={this.props.location.pathname.includes("automation") ? " active" : ""}
                >
                  <NavLink to="/automation">
                    <TiFlowMerge className="sidebar-icon" /> 
                    <p>Automation</p>
                  </NavLink>
                </li>
                <li className={this.getNavLinkClass("/leadgen")}>
                  <NavLink to="/leadgen">
                    <MdPeopleOutline className="sidebar-icon" />
                
                    <p> Leads</p>
                  </NavLink>
                </li>
                <li className={this.getNavLinkClass("/retrain")}>
                  <NavLink to="/retrain">
                    {/* <MdPeopleOutline className="sidebar-icon" /> */}
                      <img src="assets/brain.png" />
                    <p> Retrain</p>
                  </NavLink>
                </li>
                {/* <li><NavLink to="/"><FaRocketchat className="sidebar-icon" /> Live Chat</NavLink></li> */}

                <li className={this.props.location.pathname.includes("analytics") ? " active" : ""}  data-toggle="collapse"
                    data-target="#demo">
                  <NavLink
                    to="/analytics/activity"
                    className="sidebar-sub-toggle"
                  >
                    <IoMdStats className="sidebar-icon" />
                   <p> Analytics</p>
                    <span className="sidebar-collapse-icon ti-angle-down custome_icon"></span>
                  </NavLink>
                  <li className="show sidebar_dropdown_menu">
                    <div id="demo" className="collapse">
                      <ul>
                        <li >
                          <NavLink className={this.props.location.pathname.includes("analytics/activity") ? "subactive" : "subdeactive"} to="/analytics/activity">Activity</NavLink>
                        </li>
                        {/* <li>
                          <NavLink className={this.props.location.pathname.includes("analytics/topFlows") ? "subactive" : "subdeactive"} to="/analytics/topFlows">Top Flows</NavLink>
                        </li>
                        <li>
                          <NavLink className={this.props.location.pathname.includes("analytics/recentUsers") ? "subactive" : "subdeactive"} to="/analytics/recentUsers">Recent User's</NavLink>
                        </li> */}
                      </ul>
                    </div>
                  </li>
                  <ul id="uielement" className="display_hide">
                    <li>
                      <NavLink to="profiling">Profiling</NavLink>
                    </li>
                    <li>
                      <NavLink to="setting">Setting</NavLink>
                    </li>
                  </ul>
                </li>
                <li
                  style={{ cursor: "pointer", color: "gray" }}
                  className="label log_out"
                  onClick={() => {
                    this.props.logoutUser();
                  }}
                >
                  <FiLogOut className="sidebar-icon" />
                  <p>Logout</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, { logoutUser })(Sidebar);
